module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"gayatri","path":"/preview","previews":true},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"super secret"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"super secret","head":false,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
